import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today will be our monthly free class.  It’s a partner wod so bring
a friend!  8:00 & 9:00am at The Ville and 10:00 & 11:00am at East.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This coming Tuesday is the 4th of July.  We will have our free
holiday class at The Ville at 10:00am.  This will be our only class on
the 4th, all other classes and open gym will be cancelled so come out
and join in the fun!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts today.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      